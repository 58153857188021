import React, { useEffect, useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import ModulesSubMenu, { navItemsEnergy } from 'components/Submenu/ModulesSubMenu';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import BuildingSelector from 'components/BuildingSelector';
import EBSWizard from 'components/EBSWizard';
import Footer from 'components/Footer';

import { useTenant } from 'context/TenantContext';
import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';

import { SensorSelection } from '../types';
import { EnergyEBSAction, EnergyEBSState } from './types';
import { ModuleType, SensorSubType, SensorType } from 'types';

const EnergyEBSView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { getBuildingData } = useTenant();
    const { data, isLoading } = useSensorsByModulesData([ModuleType.ENERGY]);
    const [state, dispatch] = useReducer(EnergyEBSReducer, initialState);
    const {
        selectedBuilding,
        consumptionSensors,
        gasSensors,
        productionSensors,
        feedinSensors,
        equipOverview,
        suggestions,
    } = state;

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                sensorDataEnergy: data[0],
            });
        }
    }, [data]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsEnergy}
                suggestions={suggestions}
                breadCrumbs={selectedBuilding ? [selectedBuilding.name] : []}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />

            {isLoading && <LoadingOverlay />}

            {!isLoading && !equipOverview.spaces.length && (
                <div style={{ padding: '30px' }}>
                    <p>{t('energy.ebs.noEBSSensors1')}</p>

                    <p>{t('energy.ebs.noEBSSensors2')}</p>
                    <strong>
                        <p>{t('energy.ebs.noEBSMoreInfo')}</p>
                    </strong>
                </div>
            )}

            {Boolean(equipOverview.spaces.length) && !selectedBuilding && (
                <BuildingSelector
                    buildings={equipOverview}
                    onBuildingSelect={roomId => dispatch({ type: 'SELECT_SPACE', spaceId: roomId })}
                    collapsed={false}
                />
            )}
            {selectedBuilding && (
                <>
                    <EBSWizard
                        building={selectedBuilding}
                        consumptionSensors={consumptionSensors}
                        gasSensors={gasSensors}
                        productionSensors={productionSensors}
                        feedinSensors={feedinSensors}
                        closeWizard={() => dispatch({ type: 'SELECT_SPACE', spaceId: null })}
                    />
                    <Footer />
                </>
            )}
        </>
    );
};

export default EnergyEBSView;

export const initialState: EnergyEBSState = {
    selectedBuilding: null,
    suggestions: [],
    sensors: [],
    equipOverview: {
        spaces: [],
        selectedSpace: '',
    },
    consumptionSensors: [],
    gasSensors: [],
    productionSensors: [],
    feedinSensors: [],
};

const EnergyEBSReducer = (state: EnergyEBSState, action: EnergyEBSAction): EnergyEBSState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensors = [...action.sensorDataEnergy];

            const buildingStructure = action.buildingData.filter(building =>
                Boolean(
                    sensors.find(
                        s =>
                            s.isMain &&
                            s.buildingRef === building.id &&
                            s.type === SensorType.ELECTRICITY &&
                            s.subType === SensorSubType.CONSUMPTION
                    )
                )
            );

            const suggestions: SpaceSuggestion[] = [];
            buildingStructure.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
            });
            return {
                ...state,
                suggestions: suggestions,
                sensors,
                equipOverview: {
                    spaces: buildingStructure || [],
                    selectedSpace: '',
                },
            };
        }
        case 'SELECT_SPACE': {
            const selectedBuilding = state.equipOverview.spaces.find(i => i.id === action.spaceId);

            return {
                ...state,
                selectedBuilding: selectedBuilding,
                equipOverview: {
                    spaces: state.equipOverview.spaces,
                    selectedSpace: action.spaceId,
                },
                consumptionSensors: state.sensors.filter(
                    s =>
                        s.buildingRef === action.spaceId &&
                        s.type === SensorType.ELECTRICITY &&
                        s.isMain &&
                        s.subType === SensorSubType.CONSUMPTION
                ),
                gasSensors: state.sensors.filter(
                    s =>
                        s.buildingRef === action.spaceId &&
                        s.type === SensorType.GAS &&
                        s.subType === SensorSubType.CONSUMPTION
                ),
                productionSensors: state.sensors.filter(
                    s =>
                        s.buildingRef === action.spaceId &&
                        s.type === SensorType.ELECTRICITY &&
                        s.subType === SensorSubType.PRODUCTION
                ),
                feedinSensors: state.sensors.filter(
                    s => s.buildingRef === action.spaceId && s.subType === SensorSubType.FEEDIN
                ),
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);

            const selectedBuilding = state.equipOverview.spaces.find(i => i.id === sensorSelection.selectedId1);

            return {
                ...state,
                selectedBuilding: selectedBuilding,
                equipOverview: {
                    spaces: state.equipOverview.spaces,
                    selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                },
                consumptionSensors: state.sensors.filter(
                    s => s.buildingRef === sensorSelection.selectedId1 && s.subType === SensorSubType.CONSUMPTION
                ),
                gasSensors: state.sensors.filter(
                    s => s.buildingRef === sensorSelection.selectedId1 && s.subType === SensorSubType.CONSUMPTION
                ),
                productionSensors: state.sensors.filter(
                    s =>
                        s.buildingRef === sensorSelection.selectedId1 &&
                        s.type === SensorType.ELECTRICITY &&
                        s.subType === SensorSubType.PRODUCTION
                ),
                feedinSensors: state.sensors.filter(
                    s => s.buildingRef === sensorSelection.selectedId1 && s.subType === SensorSubType.FEEDIN
                ),
            };
        }
    }
};

const createSensorSelection = (ids: string[]) => {
    const sensorSelection: SensorSelection = {
        selectedId1: '',
    };
    if (!ids) {
        return sensorSelection;
    }
    if (ids[0]) {
        sensorSelection['selectedId1'] = ids[0];
    }

    return sensorSelection;
};

import React, { useEffect, useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import IncidentsContentWrapper from 'components/Container/IncidentsContentWrapper';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import IncidentsTable from 'components/Table/IncidentsTable';
import ModulesSubMenu from 'components/Submenu/ModulesSubMenu';
import { NavigationItem } from 'components/NavigationBar';
import IssuesFilter from './IssuesFilter';

import { H3 } from 'styled/components/text';

import { useIncident } from 'hooks/useIncident';
import { useIncidentsData } from 'hooks/api/useIncidentsData';

import { Incident, ModuleType } from 'types';
import { createSuggestions, filterIncidents, findIncident, searchedIncidents } from './issues.helpers';
import { IncidentsFilter, IssuesAction, IssuesState } from './types';

interface IssuesOverview {
    module: ModuleType;
    subNavItems: NavigationItem[];
    renderDataPane: (incident: Incident) => JSX.Element;
}
const IssuesOverview: React.FunctionComponent<IssuesOverview> = ({ module, subNavItems, renderDataPane }) => {
    const { t } = useTranslation();
    const { selectedIncident } = useIncident();
    const { data, isLoading, isError } = useIncidentsData(module);
    const [state, dispatch] = useReducer(issuesReducer, initialState);
    const { suggestions, incident, filteredIncidents, allIncidents } = state;

    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_INCIDENT_DATA', incidents: data, selectedIncident });
        }
    }, [data]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={subNavItems}
                suggestions={suggestions}
                onClearSearch={() => {
                    dispatch({ type: 'CLEAR_SEARCH' });
                }}
                onSearch={suggestion => dispatch({ type: 'SEARCH_INCIDENTS', searchedSpace: suggestion })}
            />

            <IncidentsContentWrapper>
                <H3>{t('view.incidents.title', { module: module })}</H3>
                <IssuesFilter onFilter={filter => dispatch({ type: 'FILTER_INCIDENTS', filter })} />
            </IncidentsContentWrapper>

            {isError && <div>{t('view.incidents.error')}</div>}

            {isLoading ? (
                <LoadingOverlay />
            ) : (
                <>
                    <IncidentsTable
                        incidents={filteredIncidents.length ? filteredIncidents : allIncidents}
                        handleSelect={(id: string, timestamp: string) =>
                            dispatch({ type: 'SET_INCIDENT', id, timestamp })
                        }
                        selectedIncident={selectedIncident}
                    />
                </>
            )}

            {incident && renderDataPane(incident)}
        </>
    );
};

export default IssuesOverview;

const initialState: IssuesState = {
    suggestions: [],
    incident: null,
    filteredIncidents: [],
    allIncidents: [],
    incidentsFilter: {},
};

const issuesReducer = (state: IssuesState, action: IssuesAction): IssuesState => {
    switch (action.type) {
        case 'SET_INCIDENT_DATA': {
            const incident = findIncident(
                action.incidents,
                action.selectedIncident?.id,
                action.selectedIncident?.timestamp
            );
            const suggestions = createSuggestions(action.incidents);

            return {
                ...state,
                incident,
                allIncidents: action.incidents,
                filteredIncidents: action.incidents,
                suggestions,
            };
        }
        case 'SET_INCIDENT': {
            const incident = findIncident(state.allIncidents, action.id, action.timestamp);

            return {
                ...state,
                incident,
            };
        }
        case 'SEARCH_INCIDENTS': {
            const filteredIncidents = searchedIncidents(state.filteredIncidents, action.searchedSpace);

            return {
                ...state,
                filteredIncidents,
            };
        }
        case 'CLEAR_SEARCH': {
            const filteredIncidents = filterIncidents(state.allIncidents, state.incidentsFilter);

            return {
                ...state,
                filteredIncidents,
            };
        }
        case 'FILTER_INCIDENTS': {
            const filter: IncidentsFilter = {
                ...state.incidentsFilter,
                ...action.filter,
            };
            const filteredIncidents = filterIncidents(state.allIncidents, filter);
            const suggestions = createSuggestions(filteredIncidents);

            return {
                ...state,
                incidentsFilter: filter,
                filteredIncidents,
                incident: null,
                suggestions,
            };
        }
    }
};

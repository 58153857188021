import React from 'react';
import { Button } from 'styled/components/buttons';
import { Modal, Overlay } from 'styled/components/popup';

interface PopupProps {
    onClose: () => void;
}

const Popup: React.FunctionComponent<PopupProps> = ({ onClose }) => {
    const handleAccept = () => {
        onClose();
        window.open('https://formless.ai/c/jcKu0txS8Flm', '_blank');
    };
    return (
        <Overlay>
            <Modal>
                <h2>Unica Building Insight</h2>
                <p>
                    Graag nodigen wij je uit om deel te nemen aan een interactieve vragenlijst t.b.v. de ontwikkeling
                    van Unica Building Insight.
                </p>
                <p>
                    De vragenlijst wordt aangedreven door kunstmatige intelligentie (AI). Je zult interactief
                    communiceren met de vragenlijst alsof je geïnterviewd wordt. Mocht je een vraag niet begrijpen of
                    terug willen gaan naar een vorige vraag, dan kun je dit aangeven.
                </p>
                <p>De vragenlijst duurt ongeveer 10 minuten en is anoniem in te vullen.</p>
                <Button onClick={() => onClose()}>Afwijzen</Button>
                <Button onClick={handleAccept}>Deelnemen</Button>
            </Modal>
        </Overlay>
    );
};

export default Popup;

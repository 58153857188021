import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import NavigationBar from 'components/NavigationBar';

import AirIssuesView from 'views/authenticated/air/AirIssuesView';
import AirStatisticsView from 'views/authenticated/air/AirStatisticsView';
import AirPreviewView from 'views/authenticated/air/AirPreviewView';
import DashboardViewFacilities from 'views/authenticated/dashboard/DashboardViewFacilities';
import DashboardViewIssues from 'views/authenticated/dashboard/DashboardViewIssues';
import EnergyIssuesView from 'views/authenticated/energy/EnergyIssuesView';
import EnergyStatisticsView from 'views/authenticated/energy/EnergyStatisticsView';
import EnergyPreviewView from 'views/authenticated/energy/EnergyPreviewView';
import EnergyEBSView from 'views/authenticated/energy/EnergyEBSView';
import EnergyGACSView from 'views/authenticated/energy/EnergyGACSView';
import EnergyMeterView from 'views/authenticated/energy/EnergyMeterView';
import MeView from 'views/authenticated/profile/MeView';
import MobilityIssuesView from 'views/authenticated/mobility/MobilityIssuesView';
import MobilityStatisticsView from 'views/authenticated/mobility/MobilityStatisticsView';
import MobilityPreviewView from 'views/authenticated/mobility/MobilityPreviewView';
import OccupancyIssuesView from 'views/authenticated/occupancy/OccupancyIssuesView';
import OccupancyStatisticsView from 'views/authenticated/occupancy/OccupancyStatisticsView';
import OccupancyPreviewView from 'views/authenticated/occupancy/OccupancyPreviewView';
import FeedbackStatisticsView from 'views/authenticated/feedback/FeedbackStatisticsView';
import FeedbackPreviewView from 'views/authenticated/feedback/FeedbackPreviewView';
import SensorsOverviewView from 'views/authenticated/sensors/SensorsOverviewView';
import SensorsIssuesView from 'views/authenticated/sensors/SensorsIssuesView';
import SupportView from 'views/authenticated/profile/SupportView';
import CompanyView from 'views/authenticated/profile/CompanyView';
import ManagementView from 'views/authenticated/profile/ManagementView';
import WaterIssuesView from 'views/authenticated/water/WaterIssuesView';
import WaterStatisticsView from 'views/authenticated/water/WaterStatisticsView';
import WaterPreviewView from 'views/authenticated/water/WaterPreviewView';

import { useTenant } from 'context/TenantContext';

import { routes } from './routes';

import { ModuleType } from 'types';
import Popup from 'components/Popup';

export default function AuthenticatedRoutes(): JSX.Element {
    const { modules } = useTenant();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        const hasAnswered = localStorage.getItem('popupAnswered');
        if (!hasAnswered) {
            setShowPopup(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('popupAnswered', 'true');
        setShowPopup(false);
    };

    const isRouteEnabled = (module: ModuleType) => {
        return modules.includes(module);
    };

    return (
        <>
            <Router>
                <NavigationBar modules={modules} />
                {showPopup && <Popup onClose={handleClose} />}
                <ErrorBoundary>
                    <Routes>
                        <Route path={routes.index} element={<Navigate to={routes.dashboard.facilities} />} />
                        <Route path={routes.dashboard.facilities} element={<DashboardViewFacilities />} />
                        <Route path={routes.dashboard.issues} element={<DashboardViewIssues />} />

                        {/* Air module */}
                        {isRouteEnabled(ModuleType.AIR) && (
                            <Route path={routes.air.issues} element={<AirIssuesView />} />
                        )}
                        {isRouteEnabled(ModuleType.AIR) && (
                            <Route path={routes.air.statistics} element={<AirStatisticsView />} />
                        )}
                        {!isRouteEnabled(ModuleType.AIR) && (
                            <Route path={routes.air.preview} element={<AirPreviewView />} />
                        )}
                        {/* Energy module */}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.issues} element={<EnergyIssuesView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.statistics} element={<EnergyStatisticsView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.ebs} element={<EnergyEBSView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.submeters} element={<EnergyMeterView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={`${routes.energy.gacs}/:buildingId/:timespan`} element={<EnergyGACSView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={`${routes.energy.gacs}/:buildingId`} element={<EnergyGACSView />} />
                        )}
                        {isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.gacs} element={<EnergyGACSView />} />
                        )}
                        {!isRouteEnabled(ModuleType.ENERGY) && (
                            <Route path={routes.energy.preview} element={<EnergyPreviewView />} />
                        )}
                        {/* Water module */}
                        {isRouteEnabled(ModuleType.WATER) && (
                            <Route path={routes.water.issues} element={<WaterIssuesView />} />
                        )}
                        {isRouteEnabled(ModuleType.WATER) && (
                            <Route path={routes.water.statistics} element={<WaterStatisticsView />} />
                        )}
                        {!isRouteEnabled(ModuleType.WATER) && (
                            <Route path={routes.water.preview} element={<WaterPreviewView />} />
                        )}
                        {/* Mobility module */}
                        {isRouteEnabled(ModuleType.MOBILITY) && (
                            <Route path={routes.mobility.issues} element={<MobilityIssuesView />} />
                        )}
                        {isRouteEnabled(ModuleType.MOBILITY) && (
                            <Route path={routes.mobility.statistics} element={<MobilityStatisticsView />} />
                        )}
                        {!isRouteEnabled(ModuleType.MOBILITY) && (
                            <Route path={routes.mobility.preview} element={<MobilityPreviewView />} />
                        )}
                        {/* Occupancy module */}
                        {isRouteEnabled(ModuleType.OCCUPANCY) && (
                            <Route path={routes.occupancy.issues} element={<OccupancyIssuesView />} />
                        )}
                        {isRouteEnabled(ModuleType.OCCUPANCY) && (
                            <Route path={routes.occupancy.statistics} element={<OccupancyStatisticsView />} />
                        )}
                        {!isRouteEnabled(ModuleType.OCCUPANCY) && (
                            <Route path={routes.occupancy.preview} element={<OccupancyPreviewView />} />
                        )}
                        {/* Feedback module */}
                        {isRouteEnabled(ModuleType.FEEDBACK) && (
                            <Route path={routes.feedback.statistics} element={<FeedbackStatisticsView />} />
                        )}
                        {!isRouteEnabled(ModuleType.FEEDBACK) && (
                            <Route path={routes.feedback.preview} element={<FeedbackPreviewView />} />
                        )}

                        <Route path={routes.sensors.overview} element={<SensorsOverviewView />} />
                        <Route path={routes.sensors.issues} element={<SensorsIssuesView />} />
                        <Route path={routes.profile.me} element={<MeView />} />
                        <Route path={routes.profile.support} element={<SupportView />} />
                        <Route path={routes.profile.company} element={<CompanyView />} />
                        <Route path={routes.profile.management} element={<ManagementView />} />
                        <Route path="*" element={<Navigate to={routes.dashboard.facilities} />} />
                    </Routes>
                </ErrorBoundary>
            </Router>
        </>
    );
}
